import React, { useEffect, useRef, useState } from "react"
import { PageProps } from "gatsby"
import gsap from "gsap";
import TextPlugin from "gsap/TextPlugin";
import firebase from "gatsby-plugin-firebase"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button";
import HomeWork from "../components/home-work";

import HomeWorksCarousel from "../components/home-works-carousel";

import { BannerContainer, Title, ButtonContainer } from './../styles/home';
import worksData from "../data/home-works";

import HomeBg from "../svgs/homebg.svg";

gsap.registerPlugin(TextPlugin);

const addText = (tl, elem, text) => {
  tl.to(elem, {
    duration: 2,
    text,
    ease: "power2"
  })
}
const removeText = (tl, elem, text) => {
  let newtext = text;
  for(let i = 0; i < text.length; i++) {
    newtext = newtext.slice(0, -1);
    tl.to(elem, {
      duration: 0.1,
      text: newtext,
      ease: "power2"
    })
  }
}

const toggleText = (tl, elem, text) => {
  addText(tl, elem, text);
  removeText(tl, elem, text);
}

const Banner = () => {
  const elem1Ref = useRef(null);
  const elem2Ref = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({ delay: 1 });
    addText(tl, elem1Ref.current, 'We brew remarkable');
    toggleText(tl, elem2Ref.current, 'web apps');
    toggleText(tl, elem2Ref.current, 'mobile apps');
    addText(tl, elem2Ref.current, 'digital experiences!');
    gsap.to(buttonRef.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 2.25
    })
    gsap.to(".bg-img", {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 0.5
    })
  }, []);

  return (
    <BannerContainer>
      <HomeBg className="bg-img" />
      <Title ref={elem1Ref} className="topTitle"  noBottom />
      <Title ref={elem2Ref} className="bottomTitle" />
      <ButtonContainer ref={buttonRef}>
        <Button>Our showreel</Button>
      </ButtonContainer>
    </BannerContainer>
  );
}

const Works = () => {
  const isMounted = useRef(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentIndexRef = useRef(0);
  const [scrollTopOffset, setScrollTopOffset] = useState({
    scroll: 0,
    top: 0
  });
  const rootRef = useRef(null);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  });

  const onScroll = (ev) => {
    if (isMounted.current) {
      setScrollTopOffset({
        scroll: ev.offset.y,
        top: rootRef.current?.offsetTop
      });
    }
  };

  const setCurrentItem = (index) => {
    if (index !== currentIndexRef.current && isMounted.current) {
      setCurrentIndex(index);
    }
    currentIndexRef.current = index;
  };

  return (
    <div ref={rootRef} id="abh-works">
      {worksData.map((work, index) => <HomeWork key={index} {...work} currentItem={worksData[currentIndex]} setCurrentItem={() => setCurrentItem(index)} scrollTopOffset={scrollTopOffset} />)}
      <HomeWorksCarousel onScroll={onScroll} currentIndex={currentIndex} works={worksData} />
    </div>
  )
};

const IndexPage: React.FC<PageProps<any>> = ({ data, path }) => {

  React.useEffect(() => {
    if (firebase) {
      firebase.analytics().logEvent('visited_homepage')
    }
  }, [firebase]);

  return (
    <Layout>
      <SEO title="Home" />
      <Banner />
      <Works />
    </Layout>
  );
}

export default IndexPage
