import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import slider from '../lib/slider';

import { getGlobals, EVENTS } from "../helpers/globals";
import { Carousel, RangeSlider, RangeSliderComplete } from "../styles/home-work";

gsap.registerPlugin(ScrollTrigger);

interface IProps {
  onScroll?: any;
  currentIndex: number;
  works?: any[];
}

const HomeWorksCarousel: React.FC<IProps> = ({ onScroll, currentIndex, works }) => {
  const isLoaded = useRef(false);
  const scroller = useRef(null);
  const sliderRef = useRef(null);
  const momentumScrollContext = useRef(getGlobals('SMOOTH_SCROLL_CONTEXT'));

  const setUpScrollProxy = () => {
    scroller.current = document.querySelector(`#abh-momentum-scroll`);
    ScrollTrigger.scrollerProxy(scroller.current, {
      scrollTop (value) {
        if (arguments.length) {
          if (momentumScrollContext.current) {
            momentumScrollContext.current.scrollTop = value;
          }
        }
        return momentumScrollContext.current?.scrollTop;
      }
    });
    if (momentumScrollContext.current) {
      momentumScrollContext.current?.addListener(ScrollTrigger.update);
    }
    ScrollTrigger.defaults({ scroller: scroller.current });
    initScrollAnims();
  }

  const initScrollAnims = () => {
    ScrollTrigger.create({
      trigger: '#abh-works',
      pin: `.abh-worksShowcase`,
      start: "0% 0%",
      end: "100% 100%",
      toggleActions: "play none none reset",
      scrub: true,
    });
    ScrollTrigger.create({
      trigger: '#abh-works',
      pin: `.abh-rangeSlider`,
      start: "0% 0%",
      end: "100% 100%",
      toggleActions: "play none none reset",
      scrub: true,
    });
  };

  const handleScroll = (ev) => {
    onScroll && onScroll(ev);
  };

  const setUpSlider = () => {
    const images = works.map(work => work.image);
    sliderRef.current = slider({
      slideImages: images,

      backgroundDisplacementSprite: "./backgroundDisplacementSprite.jpg",
      cursorDisplacementSprite: "./cursorDisplacementSprite.png",
      cursorScaleIntensity: 0.6,
      cursorMomentum: 0.14,

      swipe: false,
      swipeDistance: typeof window === "undefined" ? 300 : window.innerWidth * 0.4,
      swipeScaleIntensity: 0.3,

      slideTransitionDuration: 0.8,
      transitionScaleIntensity: 30,
      transitionScaleAmplitude: 160,

      nav: false,
      navElement: ".main-nav",

      imagesRgbEffect: false,
      imagesRgbIntensity: 4,
      navImagesRgbIntensity: 120,

      textsDisplay: true,
      textTitleSize: 144,
      textsTiltEffect: true,
      googleFonts: ["Playfair Display:400"],
      textsRgbEffect: true,
      textsRgbIntensity: 1,
    });
    isLoaded.current = true;
  };

  useEffect(() => {
    setUpSlider();
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(EVENTS.onSmoothScrollInit, () => {
        momentumScrollContext.current = getGlobals('SMOOTH_SCROLL_CONTEXT');
        if (momentumScrollContext.current) {
          momentumScrollContext.current.addListener(handleScroll);
        }
        setUpScrollProxy();
      });
    }

  }, []);

  useEffect(() => {
    if (isLoaded.current) {
      sliderRef.current.slideTransition(currentIndex);
    }
  }, [currentIndex]);

  const getPercent = () => {
    const currentWork = currentIndex + 1;
    const totalWorks = works.length;
    const percent = currentWork / totalWorks * 100;
    return `${percent}%`;
  };

  return (
    <Carousel className="abh-homeWorksCarousel">
      <div id="sliderContainer" className="abh-worksShowcase" />
      <div>
      <RangeSlider className="abh-rangeSlider">
        <span>0{currentIndex + 1}.</span>
        <RangeSliderComplete style={{ height: getPercent() }} />
        <span>0{works.length}.</span>
      </RangeSlider>
      </div>
    </Carousel>
  );
};

export default HomeWorksCarousel;
