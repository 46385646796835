import styled from "styled-components"

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 100px 230px;
  display: flex;

  @media (max-width: 599px) {
    height: auto;
    padding: 10px 0;
    flex-direction: column;
  }
  @media (min-width: 600px) {
    height: 60vh;
    padding: 100px 130px;
  }
  @media (min-width: 900px) {
    height: 100vh;
    padding: 100px 230px;
  }
`

export const LeftContainer = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) {
    width: 400px;
  }
  @media (min-width: 900px) {
    width: 400px;
  }
`

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 50px 100px;

  @media (max-width: 599px) {
    padding: 0 20px 5px;
  }
  @media (min-width: 600px) {
    padding: 0 35px 5px;
  }
  @media (min-width: 900px) {
    padding: 0 50px 100px;
  }
`

export const WorkShowCard = styled.div`
  height: 70vh;
  width: 40vh;
  margin: 20vh 5%;
  background: pink;
  display: none;

  .abh-work-item-grid-image-anchor {
    flex: 1;
  }

  .abh-work-item-grid-image {
    height: 100%;
    background: 100%;
    background-size: cover;
  }

  @media (max-width: 599px) {
    flex: 1;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
    max-width: none;
    display: flex;
  }
  @media (min-width: 600px) {
    flex: 1;
    width: 100%;
    margin-bottom: 15px;
    max-width: none;
    display: none;
  }
  @media (min-width: 900px) {
    display: none;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`

export const CompanyName = styled.h6`
  font-size: 1.5rem;
  font-weight: 400;
  padding-bottom: 36px;

  @media (max-width: 599px) {
    padding-bottom: 6px;
  }
  @media (min-width: 600px) {
    padding-bottom: 20px;
  }
  @media (min-width: 900px) {
    padding-bottom: 36px;
  }
`
export const Tagline = styled.h2`
  font-size: 2.75rem;
  font-weight: 600;
  padding-bottom: 23px;

  @media (max-width: 599px) {
    font-size: 2.25rem;
  }
  @media (min-width: 600px) {
    font-size: 2.5rem;
  }
  @media (min-width: 900px) {
    font-size: 2.75rem;
  }
`
export const Roles = styled.h6`
  font-size: 1.25rem;
  font-weight: 200;
`

export const Carousel = styled.div`
  min-height: 100vh !important;
  width: 100vw !important;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  .abh-worksShowcase {
    height: 60vh !important;
    width: 40vh !important;
    background: pink;
    position: absolute;
    left: calc(5% + 177.5px);
    top: 20vh;
    z-index: 99999999 !important;

    canvas {
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
      pointer-events: all !important;
      height: 60vh !important;
      width: 40vh !important;
    }
  }

  .button-next {
    z-index: 9999999999999;
    pointer-events: all;
  }

  .button-prev {
    z-index: 9999999999999;
    pointer-events: all;
  }

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
    display: block;
  }
  @media (min-width: 900px) {
    display: block;
  }
`

export const RangeSlider = styled.div`
  width: 2px;
  height: 50vh;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 50px;
  top: 25vh;

  span {
    font-size: 12px;
    font-weight: 700;
  }

  span:first-child {
    position: absolute;
    top: -20px;
    left: -5px;
  }

  span:last-child {
    position: absolute;
    bottom: -20px;
    left: -7px;
  }
`

export const RangeSliderComplete = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: #000;
  transition: height linear 500ms;
`
