import styled from "styled-components"

export const BannerContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;
    transform: translateY(200px);
  }

  @media (max-width: 599px) {
    height: auto;
    .bg-img {
      height: 150%;
    }
  }
  @media (min-width: 600px) {
    height: auto;
    .bg-img {
      height: 125%;
    }
  }
  @media (min-width: 900px) {
    height: 100vh;

    .bg-img {
      height: auto;
    }
  }
`
export const Title = styled.h1<any>`
  height: 100px;
  font-size: 6rem;
  font-weight: 500;
  max-width: 976px;
  text-align: center;
  margin-bottom: ${({noBottom}) => noBottom ? '20px' : '50px'};

  @media (max-width: 599px) {
    font-size: 2.5rem;
    height:  2.6rem;
    &.topTitle {
      margin-top: 30vh;
    }
  }
  @media (min-width: 600px) {
    font-size: 4rem;
    height: 4.2rem;
    &.topTitle {
      margin-top: 50px;
    }
  }
  @media (min-width: 900px) {
    font-size: 6rem;
    height: 100px;
    &.topTitle {
      margin-top: 0px;
    }
  }
`

export const ButtonContainer = styled.div`
  opacity: 0;
  transform: translateY(50px);
`
