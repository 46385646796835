import React, { useEffect, useRef } from 'react';

import PageTransition from "./page-transition";
import {
  Container, LeftContainer, RightContainer, WorkShowCard, CompanyName,
  Tagline,
  Roles
} from '../styles/home-work';

const HomeWork: React.FC<any> = ({
  id,
  image,
  setCurrentItem,
  scrollTopOffset,
  header,
  titleLine1,
  titleLine2,
  tagLine,
  currentItem
}) => {
  const itemRef = useRef(null);

  useEffect(() => {
    const topOffset = itemRef.current.offsetTop + scrollTopOffset.top;
    const bottomOffset = topOffset + itemRef.current.offsetHeight;

    if (
      topOffset - (window.innerHeight / 2) < scrollTopOffset.scroll &&
      bottomOffset > scrollTopOffset.scroll
    ) {
      setCurrentItem(id)
    }
  }, [scrollTopOffset.scroll]);

  const toPage = `/works/work`;

  return (
    <Container ref={itemRef}>
      <LeftContainer>
        <WorkShowCard>
          <PageTransition to={toPage} className="abh-work-item-grid-image-anchor">
            <div className="abh-work-item-grid-image" style={{ backgroundImage: `url(${image})` }} />
          </PageTransition>
        </WorkShowCard>
      </LeftContainer>
      <RightContainer>
        <CompanyName>{header}</CompanyName>
        <Tagline>{titleLine1} {titleLine2}</Tagline>
        <Roles>{tagLine}</Roles>
      </RightContainer>
    </Container>
  )
};

export default HomeWork;
